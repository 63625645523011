import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Journey = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Lead tracking with journeys | GlassHive"
                description="Easily track and monitor how your leads are engaging with your content with journeys in GlassHive."
            />
            <div className="journey-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text max-width-1100">
                                Automate sales & marketing with journeys
                            </h1>
                            <p className="hero-subtext white-text">
                                View processes all in one view
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'93piu7d7zm'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/jouerney-video/thumbnail.png')}
                                    alt="Journey video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Track and monitor leads
                            </h4>
                            <p>
                                With GlassHive’s journey tracking feature, you
                                can easily view how your leads are engaging with
                                your content.
                            </p>
                            <p>
                                With different conditions, you’re able to send
                                various follow up messages based on their
                                previous actions. This feature allows you to
                                tailor your content to better suit the interest
                                of your leads.
                            </p>
                            <p>
                                Check out this next-level feature by watching
                                the video!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">Fully automated</h4>
                            <p>
                                Set different conditions to follow certain
                                actions for lead generation.
                            </p>
                            <h4 className="centered-mobile">Easy to view</h4>
                            <p>
                                See the entire sales process for a lead all in
                                one place.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup
                                titleText="Start automating your marketing & sales process by signing up for GlassHive
"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Journey;
